import { createSettingsParams, SettingsParamType } from '@wix/tpa-settings';

export type IDesignSettingsParams = {
  useLabelIconColor: SettingsParamType.Boolean;
};

export const DesignSettingsParams = createSettingsParams<IDesignSettingsParams>({
  useLabelIconColor: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => true,
  },
});
