import type { PopulatedMenu } from 'root/apiTypes';
import { getMenusMap } from 'root/utils/menus';
import type { MenuOrder } from 'root/components/Menus/Settings/Tabs/MenusTab/MenusSettingsParams';
import type { ImagePosition } from 'root/consts';
import { MenusDisplayOptions } from 'root/components/Menus/Settings/Tabs/MenusTab/consts';

export const getMenusToDisplay = ({
  menusDisplayOrder,
  menus,
  menusDisplayOption,
}: {
  menusDisplayOrder: MenuOrder[];
  menus: PopulatedMenu[];
  menusDisplayOption: MenusDisplayOptions;
}) => {
  const allMenusMap = getMenusMap(menus);

  const showAllMenus = menusDisplayOption === MenusDisplayOptions.All;

  const menusToDisplay = menusDisplayOrder.reduce((acc: PopulatedMenu[], menuOrder: MenuOrder) => {
    const menu = allMenusMap[menuOrder.id];

    if (menu) {
      if (showAllMenus || menuOrder.checked) {
        acc = acc.concat(menu);
      }

      // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
      delete allMenusMap[menuOrder.id];
    }
    return acc;
  }, []);

  if (showAllMenus) {
    // Add new menus to the end
    Object.values(allMenusMap).forEach((menu) => {
      menusToDisplay.push(menu);
    });
  }

  return menusToDisplay;
};
export const getIsEndPosition = (isRTL: Boolean, position: ImagePosition, ltrStartPosition: ImagePosition) => {
  return isRTL === (position === ltrStartPosition);
};
