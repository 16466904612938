import { useSettings } from '@wix/tpa-settings/react';
import { useMemo, useState } from 'react';
import type { ItemImage, PopulatedMenu } from 'root/apiTypes';
import { getMenusToDisplay } from './utils';
import type { MenuOrder } from 'root/components/Menus/Settings/Tabs/MenusTab/MenusSettingsParams';
import { settingsParams } from 'root/components/Menus/settingsParams';
import type { MenusDisplayOptions } from 'root/components/Menus/Settings/Tabs/MenusTab/consts';
import { useExperiments, useTranslation } from '@wix/yoshi-flow-editor';
import type { LocationId, LocationsList } from 'root/types/locations';
import { Direction } from 'root/consts';
import { getOrderedLocations, getRemainingLocations, getCurrentLocation } from 'root/utils/location';
import { useGetLayoutStylesParams } from 'root/hooks/useGetLayoutStylesParams';
import { useGetDirection } from 'root/hooks/preset/useGetDirection';
import { useMenusContext } from 'root/contexts/MenusContext';

export const useMenusLogic = ({
  menus = [],
  locations = {},
  initialActiveTab,
  initialLocation,
}: {
  menus: PopulatedMenu[];
  locations?: LocationsList;
  initialActiveTab: number;
  initialLocation?: string;
}) => {
  const { t } = useTranslation();
  const settings = useSettings();
  const { experiments } = useExperiments();
  const isMultiLocationExperimentEnabled = experiments.enabled('specs.restaurants.menusLivesiteMultiLocation');
  const menusSideImage = settings.get(settingsParams.menusSideImage) as ItemImage;
  const menusDisplayOrder = settings.get(settingsParams.menusDisplayOrder!) as MenuOrder[];
  const menusDisplayOption = settings.get(settingsParams.menusDisplayOption) as MenusDisplayOptions;
  const locationDisplayOrder = settings.get(settingsParams.locationDisplayOrder!) as LocationId[];
  const [currentLocation, setCurrentLocation] = useState<LocationId | undefined>(
    initialLocation ??
      getCurrentLocation({
        locationDisplayOrder,
        locations,
        isMultiLocationExperimentEnabled,
      })
  );
  const { preset } = useGetLayoutStylesParams();
  const direction = useGetDirection(preset);
  const { isMobile } = useMenusContext();

  const displayableLocations = useMemo(() => {
    if (!isMultiLocationExperimentEnabled || !locations) {
      return {};
    }

    const orderedLocations = getOrderedLocations(locationDisplayOrder ?? [], locations, menus, menusDisplayOrder);
    const remainingLocations = getRemainingLocations(orderedLocations, locations, menus, menusDisplayOrder);
    return { ...orderedLocations, ...remainingLocations };
  }, [isMultiLocationExperimentEnabled, locations, menus, menusDisplayOrder, locationDisplayOrder]);

  const showLocations = useMemo(() => {
    return isMultiLocationExperimentEnabled && Object.keys(displayableLocations).length > 1;
  }, [isMultiLocationExperimentEnabled, displayableLocations]);

  const displayableMenus = useMemo(() => {
    let filteredMenus = menus;

    if (isMultiLocationExperimentEnabled) {
      filteredMenus = menus.filter((menu) => !menu.businessLocationDetails?.archived);
      if (showLocations && currentLocation) {
        filteredMenus = menus.filter((menu) => menu.businessLocationId === currentLocation);
      }
    }

    if (!menusDisplayOrder) {
      return filteredMenus;
    }
    return getMenusToDisplay({
      menusDisplayOrder,
      menus: filteredMenus,
      menusDisplayOption,
    });
  }, [menus, isMultiLocationExperimentEnabled, showLocations, currentLocation, menusDisplayOrder, menusDisplayOption]);

  const [activeTab, setActiveTab] = useState(initialActiveTab);

  const shouldShowTabs = displayableMenus.length > 1;
  const isRow = shouldShowTabs && showLocations && direction === Direction.Horizontal && !isMobile;

  return {
    t,
    menusSideImage,
    activeTab,
    displayableMenus,
    shouldShowTabs,
    menusDisplayOrder,
    setActiveTab,
    displayableLocations,
    showLocations,
    currentLocation,
    setCurrentLocation,
    isMultiLocationExperimentEnabled,
    isRow,
  };
};
