import { DirectionText, Direction, JustifyContentText, JustifyContent } from 'root/consts';

export const getDirectionText = (direction: Direction): string => {
  switch (direction) {
    case Direction.Horizontal:
      return DirectionText.Row;
    case Direction.Vertical:
      return DirectionText.Column;
    default:
      return DirectionText.Row;
  }
};

export const getJustifyContentText = (justifyContent: JustifyContent): string => {
  switch (justifyContent) {
    case JustifyContent.SpaceBetween:
      return JustifyContentText.SpaceBetween;
    default:
      return JustifyContentText.None;
  }
};
