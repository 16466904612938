import React from 'react';
import type { ShowcasePopulatedItem } from 'root/apiTypes';
import { st, classes } from './Item.st.css';
import { useItemLogic } from './Item.logic';
import { Labels } from 'root/components/Menus/Labels/Labels';
import { PriceVariants } from 'root/components/Menus/PriceVariants/PriceVariants';
import { Text, Divider, WowImage, ImageResizeOptions } from 'wix-ui-tpa/cssVars';
import { dataHooks } from 'root/dataHooks';
import { classes as global } from 'root/components/Menus/Global.st.css';
import { Dots } from 'root/components/Menus/Dots/Dots';

export type ItemProps = {
  item: ShowcasePopulatedItem;
};

export const Item = ({ item }: ItemProps) => {
  const {
    hasPriceVariants,
    priceContent,
    shouldShowPrice,
    shouldShowLabels,
    itemImage,
    itemTitleHtmlTag,
    itemDescriptionHtmlTag,
    itemPriceHtmlTag,
  } = useItemLogic({ item });

  return (
    <div className={classes.root} role="listitem">
      <div className={st(classes.itemContainer, itemImage ? '' : classes.noImage)} data-hook={dataHooks.item.container}>
        <Divider className={classes.divider} />
        <Text
          className={st(global.fullWidth, classes.itemName)}
          data-hook={dataHooks.item.name}
          tagName={itemTitleHtmlTag}
        >
          {item.name}
        </Text>
        {!hasPriceVariants && shouldShowPrice && <Dots />}
        {item.description && (
          <Text
            className={st(global.fullWidth, classes.itemDescription)}
            data-hook={dataHooks.item.description}
            tagName={itemDescriptionHtmlTag}
          >
            {item.description}
          </Text>
        )}
        {hasPriceVariants ? (
          <PriceVariants variants={item.priceVariants?.variants ?? []} />
        ) : (
          shouldShowPrice && (
            <div className={st(global.fullWidth, classes.itemPriceContainer)}>
              <Text className={classes.itemPrice} data-hook={dataHooks.item.price} tagName={itemPriceHtmlTag}>
                {priceContent}
              </Text>
            </div>
          )
        )}
        {shouldShowLabels && <Labels data-hook={dataHooks.item.labels} labels={item.labels!} />}
        {itemImage && (
          <WowImage
            shouldUseLQIP
            alt={item.name}
            className={classes.itemImage}
            data-hook={dataHooks.item.image}
            resize={ImageResizeOptions.cover}
            sourceHeight={itemImage?.height}
            sourceWidth={itemImage?.width}
            src={itemImage?.url ?? ''}
          />
        )}
      </div>
    </div>
  );
};
