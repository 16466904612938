import React from 'react';
import type { ItemVariant } from 'root/apiTypes';
import { Variant } from 'root/components/Menus/Variant/Variant';
import { classes } from './PriceVariants.st.css';
import { dataHooks } from 'root/dataHooks';

export const PriceVariants = ({ variants }: { variants: ItemVariant[] }) => {
  return (
    <div className={classes.priceVariants} data-hook={dataHooks.item.priceVariants} role="list">
      {variants.map((variant, index) => (
        <Variant key={variant.id} showDivider={index !== variants.length - 1} variant={variant} />
      ))}
    </div>
  );
};
