import React from 'react';
import { st, classes } from './Dots.st.css';

export const Dots = ({ variantState = false }: { variantState?: boolean }) => {
  return (
    <div className={st(classes.dotsContainer, variantState ? classes.variantState : '')}>
      <div className={classes.dots} />
    </div>
  );
};
