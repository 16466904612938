import { useGetHtmlTag } from 'root/hooks/useGetHtmlTag';
import { useExperiments } from '@wix/yoshi-flow-editor';
import { Preset } from 'root/utils/presets';
import { useStyles } from '@wix/tpa-settings/react';
import stylesParams from 'root/components/Menus/stylesParams';
import { useMenusContext } from 'root/contexts/MenusContext';
import { Plus, Minus } from '@wix/wix-ui-icons-common/on-stage';
import { ExpandCollapseIcon } from 'root/consts';

export const useSectionLogic = (isFirstSection?: boolean) => {
  const { isEditor } = useMenusContext();
  const sectionTitleHtmlTag = useGetHtmlTag('sectionTitleTheme');
  const sectionDescriptionHtmlTag = useGetHtmlTag('sectionDescriptionTheme');
  const { experiments } = useExperiments();
  const isExpandCollapsePresetExperimentEnabled = experiments.enabled('specs.restaurants.menusOoiExpandCollapsePreset');
  const styles = useStyles();
  const preset = styles.get(stylesParams.preset);
  const showSectionDescription = styles.get(stylesParams.showSectionDescription);

  const expandIcon = styles.get(stylesParams.sectionExpandCollapseIcon);
  const isExpandCollapsePreset =
    isExpandCollapsePresetExperimentEnabled && [Preset.ExpandCenter, Preset.ExpandStrip].includes(preset);
  const shouldExpand = isExpandCollapsePreset && isEditor && isFirstSection;

  return {
    sectionTitleHtmlTag,
    sectionDescriptionHtmlTag,
    isExpandCollapsePreset,
    shouldExpand,
    icons: expandIcon === ExpandCollapseIcon.Plus ? { expanded: Minus, collapsed: Plus } : undefined,
    showSectionDescription,
  };
};
