import React from 'react';
import type { PopulatedSection } from 'root/apiTypes';
import { st, classes } from './Section.st.css';
import { classes as global } from 'root/components/Menus/Global.st.css';
import { Items } from 'root/components/Menus/Items/Items';
import { Text, TextTypography, Divider } from 'wix-ui-tpa/cssVars';
import { dataHooks } from 'root/dataHooks';
import { useSectionLogic } from './Section.logic';
import { SECTION_TITLE_ID } from 'root/consts';

export const Section = ({ section, isFirstSection }: { section: PopulatedSection; isFirstSection?: boolean }) => {
  const { sectionTitleHtmlTag, sectionDescriptionHtmlTag } = useSectionLogic(isFirstSection);

  const sectionTitleId = SECTION_TITLE_ID(section.id!);
  return (
    <div className={classes.sectionContainer} data-hook={dataHooks.section.container} role="listitem">
      <Divider className={st(classes.divider, classes.dividerTop)} />
      <Text
        className={st(global.fullWidth, classes.sectionTitle)}
        data-hook={dataHooks.section.name}
        id={sectionTitleId}
        tagName={sectionTitleHtmlTag}
        typography={TextTypography.smallTitle}
      >
        {section.name}
      </Text>
      {section.description && (
        <Text
          className={st(global.fullWidth, classes.sectionDescription)}
          data-hook={dataHooks.section.description}
          tagName={sectionDescriptionHtmlTag}
        >
          {section.description}
        </Text>
      )}
      <Divider className={st(classes.divider, classes.dividerBottom)} />
      <Items items={section.items ?? []} sectionId={section.id ?? ''} sectionTitleId={sectionTitleId} />
    </div>
  );
};
