import { restaurantsLiveSiteEditorPreviewLocationNavigationClickSrc159Evid149 } from '@wix/bi-logger-restaurants-data/v2';
import { useBi, useEditorSdk } from '@wix/yoshi-flow-editor';
import { MenuListNavigation, MENU_WIDGET_NAME, NAVIGATION_VALUE_MAP } from 'root/consts';
import { getSiteData, getWidgetInstanceId } from 'root/utils/bi';
import { useEffect, useRef } from 'react';
import MenuWidget from 'root/components/Menus/.component.json';

export const useLocationsBi = () => {
  const biLogger = useBi();
  const { editorSDK } = useEditorSdk();
  const widgetName = MENU_WIDGET_NAME;
  const widgetId = MenuWidget.id;
  const widgetInstanceId = useRef<string | undefined>('');
  const pageId = useRef<string | undefined>('');
  const sitePageName = useRef<string | undefined>('');

  useEffect(() => {
    const initializeParams = async () => {
      const siteData = await getSiteData(editorSDK);
      widgetInstanceId.current = await getWidgetInstanceId(editorSDK);
      pageId.current = siteData.pageId;
      sitePageName.current = siteData.sitePageName;
    };

    initializeParams();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const reportLocationNavigationClick = ({
    locationId,
    locationName,
    numberOfLocations,
    previousLocationId,
    previousLocationName,
  }: {
    locationId: string;
    locationName: string;
    numberOfLocations: number;
    previousLocationId: string;
    previousLocationName: string;
  }) => {
    biLogger.report(
      restaurantsLiveSiteEditorPreviewLocationNavigationClickSrc159Evid149({
        widgetId,
        menuNavigation: NAVIGATION_VALUE_MAP[MenuListNavigation.Dropdown],
        numberOfLocations,
        widgetName,
        pageName: sitePageName.current,
        widgetInstanceId: widgetInstanceId.current,
        isOoi: true,
        locationId,
        locationName,
        previousLocationId,
        previousLocationName,
      })
    );
  };

  return {
    reportLocationNavigationClick,
  };
};
