import React from 'react';
import type { ShowcasePopulatedItem } from 'root/apiTypes';
import { Item } from 'root/components/Menus/Item/Item';
import { classes } from './Items.st.css';
import { dataHooks } from 'root/dataHooks';
import { ItemsContextProvider } from 'root/contexts/ItemsContext';
import { Divider } from 'wix-ui-tpa/cssVars';

export const Items = ({
  items,
  sectionId,
  sectionTitleId,
}: {
  items: ShowcasePopulatedItem[];
  sectionId: string;
  sectionTitleId: string;
}) => {
  return (
    <ItemsContextProvider value={{ sectionId }}>
      <div
        aria-labelledby={sectionTitleId}
        className={classes.itemsContainer}
        data-hook={dataHooks.items.container}
        role="list"
      >
        {items.map((item) => (
          <>
            <div className={classes.dividerContainer}>
              <Divider className={classes.divider} data-hook={dataHooks.items.divider} />
            </div>
            <Item key={item.id} item={item} />
          </>
        ))}
      </div>
    </ItemsContextProvider>
  );
};
