import { Alignment, AlignmentText, HeaderAlignment } from 'root/consts';

export const getAlignmentText = (alignment: Alignment): AlignmentText => {
  switch (alignment) {
    case Alignment.Center:
      return AlignmentText.Center;
    case Alignment.End:
      return AlignmentText.End;
    case Alignment.Start:
    default:
      return AlignmentText.Start;
  }
};

export const getAlignmentByLanguage = (currentAlignment: string, isRTL: boolean): string => {
  switch (currentAlignment) {
    case AlignmentText.Center:
      return HeaderAlignment.Center;
    case AlignmentText.Start:
      return isRTL ? HeaderAlignment.Right : HeaderAlignment.Left;
    case AlignmentText.End:
    default:
      return isRTL ? HeaderAlignment.Left : HeaderAlignment.Right;
  }
};
