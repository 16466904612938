import { useStyles } from '@wix/tpa-settings/react';
import type { IDisplayStylesParams } from 'root/components/Menus/Settings/Tabs/DisplayTab/DisplayStylesParams';
import { DisplayStylesParams } from 'root/components/Menus/Settings/Tabs/DisplayTab/DisplayStylesParams';
import { stylesParams } from 'root/components/Menus/stylesParams';

export const useGetDisplayStylesParams = () => {
  const styles = useStyles();

  const displayStylesParams = Object.fromEntries(
    Object.keys(DisplayStylesParams).map((param) => [
      param,
      styles.get(stylesParams[param as keyof IDisplayStylesParams]),
    ])
  ) as Record<keyof IDisplayStylesParams, boolean>;

  return {
    styles,
    ...displayStylesParams,
  };
};
