import { useSettings } from '@wix/tpa-settings/react';
import { useGetDisplayStylesParams } from 'root/hooks/useGetDisplayStylesParams';
import settingsParams from 'root/components/Menus/settingsParams';

export const useLabelLogic = () => {
  const settings = useSettings();
  const { showItemLabelsName } = useGetDisplayStylesParams();

  const useLabelIconColor = settings.get(settingsParams.useLabelIconColor);

  return {
    hideTooltip: showItemLabelsName,
    useLabelIconColor,
  };
};
