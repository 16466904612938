import { useStyles } from '@wix/tpa-settings/react';
import type {
  ILayoutStylesParams,
  LayoutStylesParamsValues,
} from 'root/components/Menus/Settings/Tabs/LayoutTab/LayoutStylesParams';
import { LayoutStylesParams } from 'root/components/Menus/Settings/Tabs/LayoutTab/LayoutStylesParams';
import { stylesParams } from 'root/components/Menus/stylesParams';

export const useGetLayoutStylesParams = () => {
  const styles = useStyles();

  const layoutStylesParams = Object.fromEntries(
    Object.keys(LayoutStylesParams).map((param) => [
      param,
      styles.get(stylesParams[param as keyof ILayoutStylesParams]),
    ])
  ) as LayoutStylesParamsValues;

  return layoutStylesParams;
};
