import { useMemo } from 'react';
import { useGetDisplayStylesParams } from 'root/hooks/useGetDisplayStylesParams';
import type { ItemProps } from './Item';
import { isSectionChecked, isPriceShowed } from 'root/utils/display';
import type { MenuCheckbox } from 'root/clientTypes';
import { useGetDisplaySettingsParams } from 'root/hooks/useGetDisplaySettingsParams';
import { useItemsContext } from 'root/contexts/ItemsContext';
import type { ItemImage } from 'root/apiTypes';
import { useGetHtmlTag } from 'root/hooks/useGetHtmlTag';

export const useItemLogic = ({ item }: ItemProps) => {
  const { sectionId } = useItemsContext();
  const { showItemCurrency } = useGetDisplayStylesParams();
  const {
    checkPricingMenus,
    checkPlaceholderMenu,
    imagePlaceholder,
    showZeroPrice,
    showZeroPriceToAll,
    showImagePlaceholder,
    showPlaceholderToAll,
  } = useGetDisplaySettingsParams();
  const itemTitleHtmlTag = useGetHtmlTag('itemTitleTheme');
  const itemDescriptionHtmlTag = useGetHtmlTag('itemDescriptionTheme');
  const itemPriceHtmlTag = useGetHtmlTag('itemPriceTheme');

  const hasPriceVariants = (item.priceVariants?.variants ?? []).length > 0;

  const priceContent = showItemCurrency
    ? item.priceInfo?.formattedPrice
    : item.priceInfo?.formattedPriceNoCurrency ?? '';

  const priceOptionSectionChecked =
    showZeroPriceToAll || isSectionChecked(checkPricingMenus as MenuCheckbox[], sectionId ?? '');

  const shouldShowPrice = isPriceShowed({
    price: item.priceInfo?.price ?? '',
    showZeroPrice: showZeroPrice as boolean,
    sectionChecked: priceOptionSectionChecked as boolean,
  });

  const shouldShowLabels = item.labels && item.labels.length > 0;

  const imagePlaceholderSectionChecked =
    showPlaceholderToAll || isSectionChecked(checkPlaceholderMenu as MenuCheckbox[], sectionId ?? '');

  const shouldShowImagePlaceholder = useMemo(
    () => (showImagePlaceholder ? imagePlaceholderSectionChecked : false),
    [imagePlaceholderSectionChecked, showImagePlaceholder]
  );

  const itemImage = useMemo(
    () => (item?.image ? item?.image : shouldShowImagePlaceholder ? imagePlaceholder : undefined) as ItemImage,
    [imagePlaceholder, item?.image, shouldShowImagePlaceholder]
  );

  return {
    hasPriceVariants,
    priceContent,
    shouldShowPrice,
    shouldShowLabels,
    shouldShowImagePlaceholder,
    itemImage,
    itemTitleHtmlTag,
    itemDescriptionHtmlTag,
    itemPriceHtmlTag,
  };
};
