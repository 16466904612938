import { menusLiveSiteEditorPreviewNavigationClick } from '@wix/bi-logger-restaurants-data/v2';
import { useBi, useEditorSdk } from '@wix/yoshi-flow-editor';
import type { MenuListNavigation } from 'root/consts';
import { MENU_WIDGET_NAME, NAVIGATION_VALUE_MAP } from 'root/consts';
import { getSiteData, getWidgetInstanceId } from 'root/utils/bi';
import { useEffect, useRef } from 'react';
import MenuWidget from 'root/components/Menus/.component.json';

export const useNavigationBi = () => {
  const biLogger = useBi();
  const { editorSDK } = useEditorSdk();
  const widgetName = MENU_WIDGET_NAME;
  const widgetId = MenuWidget.id;
  const widgetInstanceId = useRef<string | undefined>('');
  const pageId = useRef<string | undefined>('');
  const sitePageName = useRef<string | undefined>('');

  useEffect(() => {
    const initializeParams = async () => {
      const siteData = await getSiteData(editorSDK);
      widgetInstanceId.current = await getWidgetInstanceId(editorSDK);
      pageId.current = siteData.pageId;
      sitePageName.current = siteData.sitePageName;
    };

    initializeParams();
  }, []);

  const reportMenuNavigationClick = ({
    navigationLayout,
    numMenus,
  }: {
    navigationLayout: MenuListNavigation;
    numMenus: number;
  }) => {
    biLogger.report(
      menusLiveSiteEditorPreviewNavigationClick({
        widgetId,
        menuNavigation: NAVIGATION_VALUE_MAP[navigationLayout],
        numMenus,
        widgetName,
        pageName: sitePageName.current,
        widgetInstanceId: widgetInstanceId.current,
        isOoi: true,
      })
    );
  };

  return {
    reportMenuNavigationClick,
  };
};
