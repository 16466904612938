import { SettingsParamType, createSettingsParams } from '@wix/tpa-settings';
import type { ItemImage } from 'root/apiTypes';
import { DEFAULT_PLACEHOLDER_IMAGE } from 'root/assets/images/ItemPlaceholderImage';
import { DEFAULT_MENUS_SIDE_IMAGE } from 'root/assets/images/MenusSideImage';
import type { ExtractSettingParamValue } from 'root/components/Menus/settingsParams';

export type IDisplaySettingsParams = {
  checkPlaceholderMenu?: SettingsParamType.Object;
  checkPricingMenus?: SettingsParamType.Object;
  imagePlaceholder: SettingsParamType.Object;
  menusSideImage: SettingsParamType.Object;
  showZeroPrice: SettingsParamType.Boolean;
  showZeroPriceToAll: SettingsParamType.Boolean;
  showImagePlaceholder: SettingsParamType.Boolean;
  showPlaceholderToAll: SettingsParamType.Boolean;
};

export type MenuOrder = { id: string; checked: boolean };

export const DisplaySettingsParams = createSettingsParams<IDisplaySettingsParams>({
  checkPlaceholderMenu: {
    type: SettingsParamType.Object,
    getDefaultValue: () => undefined,
  },
  checkPricingMenus: {
    type: SettingsParamType.Object,
    getDefaultValue: () => undefined,
  },
  imagePlaceholder: {
    type: SettingsParamType.Object,
    getDefaultValue: () =>
      ({
        url: DEFAULT_PLACEHOLDER_IMAGE,
      } as ItemImage),
  },
  menusSideImage: {
    type: SettingsParamType.Object,
    getDefaultValue: () =>
      ({
        url: DEFAULT_MENUS_SIDE_IMAGE,
      } as ItemImage),
  },
  showZeroPrice: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => true,
  },
  showZeroPriceToAll: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => true,
  },
  showImagePlaceholder: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => false,
  },
  showPlaceholderToAll: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => true,
  },
});

export type DisplaySettingsParamsValue = {
  [K in keyof IDisplaySettingsParams]: ExtractSettingParamValue<(typeof DisplaySettingsParams)[K]>;
};
