import { createContext, useContext } from 'react';

type ContextType = {
  sectionId?: string;
};

export const ItemsContext = createContext<ContextType>({});

export const ItemsContextProvider = ItemsContext.Provider;

export const useItemsContext = () => {
  const context = useContext(ItemsContext);
  if (!context) {
    throw new Error('useItemsContext must be used within an ItemsContextProvider');
  }
  return context;
};
