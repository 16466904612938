import type { LocationId, LocationsList, MenusByLocationMap } from 'root/types/locations';
import type { Menu, PopulatedMenu } from 'root/apiTypes';
import type { MenusWithSections } from 'root/clientTypes';
import type { LocationOrder, MenuOrder } from 'root/components/Menus/Settings/Tabs/MenusTab/MenusSettingsParams';
import type { MenusDisplayOptions } from 'root/components/Menus/Settings/Tabs/MenusTab/consts';
import type { SortedLocation, SortedMenu } from 'root/components/Menus/Settings/Tabs/MenusTab/types';
import { getSortedMenus } from 'root/components/Menus/Settings/Tabs/MenusTab/utils';

export const getLocations = (menus: PopulatedMenu[] | Menu[]) => {
  return (menus as (PopulatedMenu | Menu)[]).reduce<LocationsList>((acc, menu) => {
    const locationId = menu.businessLocationId;
    const locationName = menu.businessLocationDetails?.name;
    const archived = menu.businessLocationDetails?.archived;
    if (locationId && locationName && !archived) {
      acc[locationId] = locationName;
    }
    return acc;
  }, {});
};

export const getMenusByLocationMap = (locations: LocationsList, menus: MenusWithSections) => {
  return Object.entries(locations).reduce((acc, [locationId, locationName]) => {
    acc[locationId] = {
      name: locationName,
      menus: menus.filter((menu) => menu.businessLocationId === locationId),
    };
    return acc;
  }, {} as MenusByLocationMap);
};

export const getSortedLocations = ({
  locationDisplayOrder = [],
  menusDisplayOrder = [],
  menus,
  menusDisplayOption,
  locations = {},
}: {
  locationDisplayOrder?: LocationOrder;
  menusDisplayOrder?: MenuOrder[];
  menus: MenusWithSections;
  menusDisplayOption: MenusDisplayOptions;
  locations?: LocationsList;
}): SortedLocation[] => {
  const menusByLocationMap = getMenusByLocationMap(locations, menus);

  const sortedLocations = locationDisplayOrder.reduce((acc, locationOrder) => {
    const location = { id: locationOrder ?? '', name: locations[locationOrder!] };

    if (location.id && location.name) {
      const sortedMenus = getSortedMenus({
        menus: menusByLocationMap[location.id].menus,
        menusDisplayOption,
        menusDisplayOrder,
      });

      acc = acc.concat({
        id: location.id,
        name: location.name,
        checked: sortedMenus.some((menu) => menu.checked),
        menus: sortedMenus.map((menu) => menu.id),
      });

      // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
      delete locations[location.id];
    }
    return acc;
  }, [] as SortedLocation[]);

  // Add new locations that weren't in the saved locationDisplayOrder to the end
  Object.keys(locations).forEach((location) => {
    const sortedMenus = getSortedMenus({
      menus: menusByLocationMap[location].menus,
      menusDisplayOption,
      menusDisplayOrder,
    });

    sortedLocations.push({
      id: location,
      name: locations[location],
      checked: sortedMenus.some((menu) => menu.checked),
      menus: sortedMenus.map((menu) => menu.id),
    });
  });

  return sortedLocations;
};

const hasCheckedMenusInLocation = (locationId: string, menus: PopulatedMenu[], menusDisplayOrder: MenuOrder[]) => {
  if (!menusDisplayOrder) {
    return true;
  }
  const locationMenus = menus.filter((menu) => menu.businessLocationId === locationId);
  return locationMenus?.some((menu) => {
    const orderMenu = menusDisplayOrder?.find((order) => order.id === menu.id);
    return orderMenu ? orderMenu.checked : true;
  });
};

export const getOrderedLocations = (
  locationDisplayOrder: LocationId[],
  locations: LocationsList,
  menus: PopulatedMenu[],
  menusDisplayOrder: MenuOrder[]
) => {
  return locationDisplayOrder.reduce<LocationsList>((acc, locationId) => {
    if (locations[locationId] && hasCheckedMenusInLocation(locationId, menus, menusDisplayOrder ?? [])) {
      acc[locationId] = locations[locationId];
    }
    return acc;
  }, {});
};

export const getRemainingLocations = (
  orderedLocations: LocationsList,
  locations: LocationsList,
  menus: PopulatedMenu[],
  menusDisplayOrder: MenuOrder[]
) => {
  return Object.entries(locations).reduce<LocationsList>((acc, [locationId, location]) => {
    if (!orderedLocations[locationId] && hasCheckedMenusInLocation(locationId, menus, menusDisplayOrder)) {
      acc[locationId] = location;
    }
    return acc;
  }, {});
};

export const updateSortedMenuForSingleMenu = (
  sortedMenus: SortedMenu[],
  menuId: string,
  checked: boolean
): SortedMenu[] => {
  return sortedMenus.map((sortedMenu) => (sortedMenu.id === menuId ? { ...sortedMenu, checked } : sortedMenu));
};

export const updateSortedLocationsForSingleMenu = (
  sortedLocations: SortedLocation[],
  locationId: string,
  menuId: string,
  updatedSortedMenus: SortedMenu[]
): SortedLocation[] => {
  return sortedLocations.map((location) => {
    if (location.id === locationId) {
      if (updatedSortedMenus.find((menu) => menu.id === menuId)?.checked) {
        return { ...location, checked: true };
      } else {
        const otherMenusInLocation = location.menus.filter((id) => id !== menuId);
        const hasOtherCheckedMenus = otherMenusInLocation.some(
          (id) => updatedSortedMenus.find((sortedMenu) => sortedMenu.id === id)?.checked
        );
        return { ...location, checked: hasOtherCheckedMenus };
      }
    }
    return location;
  });
};

export const updateSortedMenusForLocation = (
  sortedMenus: SortedMenu[],
  locationMenuIds: string[],
  checked: boolean
): SortedMenu[] => {
  return sortedMenus.map((menu) => {
    if (locationMenuIds.includes(menu.id)) {
      return {
        ...menu,
        checked,
      };
    }
    return menu;
  });
};

export const updateSortedLocationsForLocation = (
  sortedLocations: SortedLocation[],
  locationId: string,
  locationMenuIds: string[],
  checked: boolean
): SortedLocation[] => {
  return sortedLocations.map((location) => {
    if (location.id === locationId) {
      return {
        ...location,
        checked,
        menus: locationMenuIds,
      };
    }
    return location;
  });
};

export const getCurrentLocation = ({
  isMultiLocationExperimentEnabled,
  locationDisplayOrder,
  locations,
}: {
  isMultiLocationExperimentEnabled: boolean;
  locationDisplayOrder: LocationId[];
  locations: LocationsList;
}) => {
  if (isMultiLocationExperimentEnabled) {
    if (locationDisplayOrder?.length) {
      return locationDisplayOrder?.[0];
    } else {
      const locationIds = Object.keys(locations);
      if (locationIds.length > 1) {
        const firstAvailableLocation = locationDisplayOrder?.find((id) => locations[id]) ?? locationIds[0];
        if (firstAvailableLocation) {
          return firstAvailableLocation;
        }
      }
    }
  }
  return;
};
