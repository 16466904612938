import { Preset } from 'root/utils/presets';
import { useGetLayoutStylesParams } from 'root/hooks/useGetLayoutStylesParams';
import type { Direction } from 'root/consts';

export const useGetDirection = (preset: Preset) => {
  const {
    gridHeaderFlexDirection,
    columnsHeaderFlexDirection,
    sideBySideHeaderFlexDirection,
    centerHeaderFlexDirection,
    expandStripHeaderFlexDirection,
    expandCenterHeaderFlexDirection,
  } = useGetLayoutStylesParams();

  const directionMap: Record<Preset, Direction> = {
    [Preset.Grid]: gridHeaderFlexDirection,
    [Preset.Columns]: columnsHeaderFlexDirection,
    [Preset.Center]: centerHeaderFlexDirection,
    [Preset.SideBySide]: sideBySideHeaderFlexDirection,
    [Preset.ExpandStrip]: expandStripHeaderFlexDirection,
    [Preset.ExpandCenter]: expandCenterHeaderFlexDirection,
  };

  return directionMap[preset];
};
