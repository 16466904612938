import type {
  ISettingsParam,
  ISettingsParamValuesByType,
  SettingsParamType,
  createSettingsParams,
} from '@wix/tpa-settings';
import { MenusSettingsParams, type IMenusSettingsParams } from './Settings/Tabs/MenusTab/MenusSettingsParams';
import type { IDisplaySettingsParams } from './Settings/Tabs/DisplayTab/DisplaySettingsParams';
import { DisplaySettingsParams } from './Settings/Tabs/DisplayTab/DisplaySettingsParams';
import type { IDesignSettingsParams } from './Settings/Tabs/DesignTab/DesignSettingsParams';
import { DesignSettingsParams } from './Settings/Tabs/DesignTab/DesignSettingsParams';
// eslint-disable-next-line no-restricted-imports

export type SettingsParamsType<T extends Record<string, SettingsParamType>> = ReturnType<
  typeof createSettingsParams<T>
>;

export type ISettingsParams = IMenusSettingsParams & IDisplaySettingsParams & IDesignSettingsParams;

export const settingsParams: SettingsParamsType<ISettingsParams> = {
  ...MenusSettingsParams,
  ...DisplaySettingsParams,
  ...DesignSettingsParams,
};

export default settingsParams;

export type SettingParamValue = ISettingsParamValuesByType[keyof ISettingsParamValuesByType];

export type ExtractSettingParamValue<T> = T extends ISettingsParam<SettingsParamType, infer U> ? U : never;

export type SettingParamsByType<T extends SettingsParamType> = {
  [K in keyof ISettingsParams as ISettingsParams[K] extends T ? K : never]: ISettingsParams[K];
};

export type SettingParamKeysByType<T extends SettingsParamType> = keyof SettingParamsByType<T>;
