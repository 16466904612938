import { useSettings } from '@wix/tpa-settings/react';
import type { IDisplaySettingsParams } from 'root/components/Menus/Settings/Tabs/DisplayTab/DisplaySettingsParams';
import { DisplaySettingsParams } from 'root/components/Menus/Settings/Tabs/DisplayTab/DisplaySettingsParams';
import type { SettingParamValue } from 'root/components/Menus/settingsParams';
import { settingsParams } from 'root/components/Menus/settingsParams';

export const useGetDisplaySettingsParams = () => {
  const settings = useSettings();

  const displaySettingsParams = Object.fromEntries(
    Object.keys(DisplaySettingsParams).map((param) => [
      param,
      settings.get(settingsParams[param as keyof IDisplaySettingsParams]!),
    ])
  ) as Record<keyof IDisplaySettingsParams, SettingParamValue>;

  return {
    settings,
    ...displaySettingsParams,
  };
};
