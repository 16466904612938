import {
  LABEL_TITLE_COLUMNS_MAX_SIZE,
  LABEL_TITLE_GRID_MAX_SIZE,
} from 'root/components/Menus/Settings/Tabs/DesignTab/DesignSections/LabelsDesign/consts';

export enum Preset {
  Columns = 0,
  Grid = 1,
  Center = 2,
  SideBySide = 3,
  ExpandStrip = 4,
  ExpandCenter = 5,
}

export enum ExpandType {
  Strip = Preset.ExpandStrip,
  Center = Preset.ExpandCenter,
}

export const getPresetName = (preset: Preset) => {
  switch (preset) {
    case Preset.Grid:
      return 'grid';
    case Preset.Columns:
      return 'columns';
    case Preset.Center:
      return 'center';
    case Preset.SideBySide:
      return 'sideBySide';
    case Preset.ExpandStrip:
      return 'expandStrip';
    case Preset.ExpandCenter:
      return 'expandCenter';
    default:
      return '';
  }
};

export const hasHorizontalImageOptions = (preset: Preset) => preset === Preset.Grid;
export const hasImageByDefault = (preset: Preset) => preset === Preset.Grid;

export const hasSectionTopDivider = (preset: Preset) => [Preset.Grid, Preset.Center].includes(preset);
export const hasSectionBottomDivider = (preset: Preset) => preset === Preset.Grid;

export const hasItemDivider = (preset: Preset) =>
  [Preset.Columns, Preset.ExpandStrip, Preset.SideBySide].includes(preset);
export const hasItemImage = (preset: Preset) => [Preset.Grid, Preset.ExpandStrip, Preset.Columns].includes(preset);

export const hasPriceVariantDividers = (preset: Preset) => preset === Preset.Center;

export const getMaxLabelFontSize = (preset: Preset) =>
  preset === Preset.Grid ? LABEL_TITLE_GRID_MAX_SIZE : LABEL_TITLE_COLUMNS_MAX_SIZE;
