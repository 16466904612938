import { Preset } from 'root/utils/presets';
import { useGetLayoutStylesParams } from 'root/hooks/useGetLayoutStylesParams';
import type { MenuListNavigation } from 'root/consts';

export const useGetNavigationLayout = (preset: Preset) => {
  const {
    gridMenuNavigationLayout,
    columnsMenuNavigationLayout,
    sideBySideMenuNavigationLayout,
    centerMenuNavigationLayout,
    expandStripMenuNavigationLayout,
    expandCenterMenuNavigationLayout,
  } = useGetLayoutStylesParams();

  const navigationLayoutMap: Record<Preset, MenuListNavigation> = {
    [Preset.Grid]: gridMenuNavigationLayout,
    [Preset.Columns]: columnsMenuNavigationLayout,
    [Preset.Center]: centerMenuNavigationLayout,
    [Preset.SideBySide]: sideBySideMenuNavigationLayout,
    [Preset.ExpandStrip]: expandStripMenuNavigationLayout,
    [Preset.ExpandCenter]: expandCenterMenuNavigationLayout,
  };

  return navigationLayoutMap[preset];
};
