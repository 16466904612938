import React from 'react';
import { Text } from 'wix-ui-tpa/cssVars';
import { classes } from './MenuState.st.css';
import { dataHooks } from 'root/dataHooks';

export const MenuState = ({ content }: { content: string }) => {
  return (
    <div className={classes.menuState} data-hook={dataHooks.menus.menuState.container}>
      <Text className={classes.content} data-hook={dataHooks.menus.menuState.content} tagName="h2">
        {content}
      </Text>
    </div>
  );
};
