import type { StyleParamType } from '@wix/tpa-settings';
import { useStyles } from '@wix/tpa-settings/react';
import type { StyleParamKeysByType } from 'root/components/Menus/stylesParams';
import stylesParams from 'root/components/Menus/stylesParams';

export const useGetHtmlTag = (fontStyleParam: StyleParamKeysByType<StyleParamType.Font>) => {
  const styles = useStyles();

  const fontStyle = styles.get(stylesParams[fontStyleParam]);

  return fontStyle.htmlTag;
};
