import type { SortedMenu } from './types';
import { getMenusMap } from 'root/utils/menus';
import type { MenuOrder } from './MenusSettingsParams';
import type { MenusWithSections } from 'root/clientTypes';
import { MenusDisplayOptions } from './consts';

export const getDisplayValue = (mobileValue: number, desktopValue: number, isMobile?: boolean) =>
  isMobile ? mobileValue : desktopValue;

export const getSortedMenus = ({
  menusDisplayOrder = [],
  menus,
  menusDisplayOption,
}: {
  menusDisplayOrder?: MenuOrder[];
  menus: MenusWithSections;
  menusDisplayOption: MenusDisplayOptions;
}): SortedMenu[] => {
  const showAllMenus = menusDisplayOption === MenusDisplayOptions.All;
  const menusMap = getMenusMap(menus);
  const sortedMenus = menusDisplayOrder.reduce((acc, menuOrder) => {
    const menu = menusMap[menuOrder.id];

    if (menu && !menu.businessLocationDetails?.archived) {
      acc = acc.concat({
        id: menu.id as string,
        name: menu.name as string,
        checked: menuOrder.checked,
        draft: !menu.visible,
      });

      // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
      delete menusMap[menuOrder.id];
    }
    return acc;
  }, [] as SortedMenu[]);

  // Add new menus that weren't in the saved menusDisplayOrder to the end
  Object.values(menusMap).forEach((menu) => {
    const isDraft = !menu.visible;
    if (menu.businessLocationDetails?.archived) {
      return;
    }

    sortedMenus.push({
      id: menu.id as string,
      name: menu.name as string,
      checked: isDraft ? false : showAllMenus,
      draft: isDraft,
    });
  });

  return sortedMenus;
};
