import type { IStyleParam, IStyleParamValuesByType, StyleParamType, createStylesParams } from '@wix/tpa-settings';
import type { IDisplayStylesParams } from './Settings/Tabs/DisplayTab/DisplayStylesParams';
import { DisplayStylesParams } from './Settings/Tabs/DisplayTab/DisplayStylesParams';
import type { IDesignStylesParams } from './Settings/Tabs/DesignTab/DesignStylesParams';
import { DesignStylesParams } from './Settings/Tabs/DesignTab/DesignStylesParams';
import type { ILayoutStylesParams } from './Settings/Tabs/LayoutTab/LayoutStylesParams';
import { LayoutStylesParams } from './Settings/Tabs/LayoutTab/LayoutStylesParams';
import type { CustomCssVarsFn } from '@wix/yoshi-flow-editor';
import {
  getColumnsImageWidth,
  getItemTextAlignment,
  getMenuMargin,
  getMenuMaxWidth,
  getMenuTextAlignment,
  getNavigationMargin,
  getSectionsColumns,
  getSectionsColumnsGap,
  getSectionsDisplay,
  getSectionsGridTemplateColumns,
  getSectionsMarginTop,
  getSectionsRowGap,
  getSectionTextAlignment,
  getWidgetGridTemplate,
  showMenuSideImage,
  showPriceVariantsDivider,
  getElementDisplay,
  getSectionPadding,
  getSectionMarginBottom,
  getFirstSectionMarginTop,
  getSectionTopDividerMarginBottom,
  getItemsDisplay,
  getItemsGridTemplateColumns,
  getItemsRowGap,
  getItemsColumnGap,
  getItemRootMargin,
  getItemGridTemplate,
  getItemGridTemplateNoImage,
  getItemPadding,
  getItemPriceJustifySelf,
  getItemPriceMarginBottom,
  getItemImageAspectRatio,
  getItemImageMarginBottom,
  getPriceVariantsDisplay,
  getPriceVariantsJustifyContent,
  getPriceVariantsAlignItems,
  getPriceVariantsFlexWrap,
  getPriceVariantsGap,
  getPriceVariantsMarginBottom,
  getVariantDisplay,
  getVariantGridTemplate,
  getVariantAlignItems,
  getVariantWidth,
  getVariantDetailsJustifyContent,
  getVariantDetailsGridArea,
  getWidgetPadding,
  getWidgetOverflow,
  getWidgetDisplay,
  getMenuContentGridArea,
  getMenuContentPadding,
  getImageContainerGridArea,
  getImageContainerPosition,
  getImageContainerTop,
  getImageContainerWidth,
  getImageContainerHeight,
  getImageContainerPaddingBottom,
  getSectionBottomDividerMarginTop,
  getHeaderFlexDirection,
  getHeaderContainerGridArea,
  getHeaderContainerPadding,
  getItemPriceWidth,
  convertAlignmentToJustifyContent,
  getDotSvgUrl,
  getHeaderJustifyContent,
  getHeaderAlignItems,
  getFirstItemDividerMarginBottom,
  getNameAndPriceAlignSelf,
  getDotsMargin,
} from './stylesParamsUtils';
import { hasItemDivider, hasItemImage, hasSectionBottomDivider, hasSectionTopDivider } from 'root/utils/presets';

export type StylesParamsType<T extends Record<string, StyleParamType>> = ReturnType<typeof createStylesParams<T>>;

export type IStylesParams = IDesignStylesParams & IDisplayStylesParams & ILayoutStylesParams;

export const stylesParams: StylesParamsType<IStylesParams> = {
  ...DisplayStylesParams,
  ...DesignStylesParams,
  ...LayoutStylesParams,
};

export default stylesParams;

export type StyleParamKey = keyof typeof stylesParams;
export type StyleParamValue = IStyleParamValuesByType[keyof IStyleParamValuesByType];

export type StyleParamsByType<T extends StyleParamType> = {
  [K in keyof IStylesParams as IStylesParams[K] extends T ? K : never]: IStylesParams[K];
};

export type StyleParamKeysByType<T extends StyleParamType> = keyof StyleParamsByType<T>;

export type ExtractStyleParamValue<T> = T extends IStyleParam<StyleParamType, infer U> ? U : never;

export const customCssVars: CustomCssVarsFn = ({ isMobile, isRTL, styleParams, tpaData }) => {
  const preset = styleParams.numbers.preset;

  const columnsImageWidth = getColumnsImageWidth({
    imageWidthEnum: styleParams.numbers.columnsItemImageSize,
    isMobile,
  });
  const expandStripImageWidth = getColumnsImageWidth({
    imageWidthEnum: styleParams.numbers.expandStripItemImageSize,
    isMobile,
  });
  const menuTextAlignment = getMenuTextAlignment({
    gridMenuAlignment: styleParams.numbers.gridMenuAlignment,
    columnsMenuAlignment: styleParams.numbers.columnsMenuAlignment,
    sideBySideMenuAlignment: styleParams.numbers.sideBySideMenuAlignment,
    expandStripMenuAlignment: styleParams.numbers.expandStripMenuAlignment,
    preset,
  });

  const sectionTextAlignment = getSectionTextAlignment({
    gridSectionAlignment: styleParams.numbers.gridSectionAlignment,
    columnsSectionAlignment: styleParams.numbers.columnsSectionAlignment,
    sideBySideSectionAlignment: styleParams.numbers.sideBySideSectionAlignment,
    expandStripSectionAlignment: styleParams.numbers.expandStripSectionAlignment,
    preset,
  });

  const itemTextAlignment = getItemTextAlignment({
    gridItemAlignment: styleParams.numbers.gridItemAlignment,
    preset,
  });

  const priceJustifyContent = convertAlignmentToJustifyContent({
    alignment: itemTextAlignment,
  });

  const navigationTextAlignment = getHeaderAlignItems({
    preset,
    isMobile,
    gridHeaderAlignItems: styleParams.numbers.gridMenuNavigationAlignment,
    columnsHeaderAlignItems: styleParams.numbers.columnsMenuNavigationAlignment,
    sideBySideHeaderAlignItems: styleParams.numbers.sideBySideMenuNavigationAlignment,
    centerHeaderAlignItems: styleParams.numbers.centerMenuNavigationAlignment,
    expandStripHeaderAlignItems: styleParams.numbers.expandStripMenuNavigationAlignment,
    expandCenterHeaderAlignItems: styleParams.numbers.expandCenterMenuNavigationAlignment,
  });

  const showVariantsDivider = showPriceVariantsDivider({
    preset,
    showItemVariantsDivider: styleParams.booleans.showItemVariantsDivider,
  });

  const showMenusSideImage = showMenuSideImage({
    preset,
    isMobile,
    showMenusSideImage: styleParams.booleans.showMenusSideImage,
  });

  const widgetGridTemplate = getWidgetGridTemplate({
    preset,
    isMobile,
    isRTL,
    imagePosition: styleParams.numbers.sideBySideImagePosition,
  });

  const widgetPadding = getWidgetPadding({
    preset,
    gridMenuVerticalPadding: styleParams.numbers.gridMenuVerticalPadding,
    gridMenuHorizontalPadding: styleParams.numbers.gridMenuHorizontalPadding,
    columnsMenuVerticalPadding: styleParams.numbers.columnsMenuVerticalPadding,
    columnsMenuHorizontalPadding: styleParams.numbers.columnsMenuHorizontalPadding,
    centerMenuVerticalPadding: styleParams.numbers.centerMenuVerticalPadding,
    centerMenuHorizontalPadding: styleParams.numbers.centerMenuHorizontalPadding,
    sideBySideMenuVerticalPadding: styleParams.numbers.sideBySideMenuVerticalPadding,
    sideBySideMenuHorizontalPadding: styleParams.numbers.sideBySideMenuHorizontalPadding,
    expandStripMenuVerticalPadding: styleParams.numbers.expandStripMenuVerticalPadding,
    expandStripMenuHorizontalPadding: styleParams.numbers.expandStripMenuHorizontalPadding,
    expandCenterMenuVerticalPadding: styleParams.numbers.expandCenterMenuVerticalPadding,
    expandCenterMenuHorizontalPadding: styleParams.numbers.expandCenterMenuHorizontalPadding,
  });

  const widgetOverflow = getWidgetOverflow({ preset, isMobile });
  const widgetDisplay = getWidgetDisplay({ preset });
  const headerContainerPadding = getHeaderContainerPadding({ preset, isMobile });
  const menuContentGridArea = getMenuContentGridArea({ preset });
  const menuContentPadding = getMenuContentPadding({ preset, isMobile });
  const imageContainerGridArea = getImageContainerGridArea({ preset });
  const imageContainerPosition = getImageContainerPosition({ preset });
  const imageContainerTop = getImageContainerTop({ preset });
  const imageContainerWidth = getImageContainerWidth({ preset });
  const imageContainerHeight = getImageContainerHeight({ preset, isMobile });
  const imageContainerPaddingBottom = getImageContainerPaddingBottom({ preset, isMobile });

  const navigationMargin = getNavigationMargin();
  const menuMaxWidth = getMenuMaxWidth({ preset });
  const menuMargin = getMenuMargin({ preset });
  const sectionsDisplay = getSectionsDisplay({ preset });
  const sectionsGridTemplateColumns = getSectionsGridTemplateColumns({ preset });
  const sectionsRowGap = getSectionsRowGap({
    preset,
    gridGap: styleParams.numbers.gridSectionsGap,
    centerGap: styleParams.numbers.centerSectionsGap,
    expandStripGap: styleParams.numbers.expandStripSectionsGap,
    expandCenterGap: styleParams.numbers.expandCenterSectionsGap,
  });
  const sectionsMarginTop = getSectionsMarginTop({
    preset,
    showMenuName: styleParams.booleans.showMenuName,
    showMenuDescription: styleParams.booleans.showMenuDescription,
  });
  const sectionsColumns = getSectionsColumns({
    preset,
    isMobile,
    columnsNumOfColumns: styleParams.numbers.columnsNumOfColumns,
  });
  const sectionsColumnsGap = getSectionsColumnsGap({
    preset,
    columnsGap: styleParams.numbers.columnsColumnsGap,
  });
  const sectionPadding = getSectionPadding({
    preset,
    gridPadding: styleParams.numbers.gridSectionPadding,
    columnsPadding: styleParams.numbers.columnsSectionPadding,
    centerPadding: styleParams.numbers.centerSectionPadding,
    sideBySidePadding: styleParams.numbers.sideBySideSectionPadding,
    expandStripPadding: [
      styleParams.numbers.expandStripSectionPaddingVertical,
      styleParams.numbers.expandStripSectionPaddingHorizontal,
    ],
    expandCenterPadding: [
      styleParams.numbers.expandCenterSectionPaddingVertical,
      styleParams.numbers.expandCenterSectionPaddingHorizontal,
    ],
  });
  const sectionMarginBottom = getSectionMarginBottom({
    preset,
    columnsMarginBottom: styleParams.numbers.columnsSectionsGap,
    sideBySideMarginBottom: styleParams.numbers.sideBySideSectionsGap,
    expandStripMarginBottom: styleParams.numbers.expandStripSectionsGap,
  });
  const firstSectionMarginTop = getFirstSectionMarginTop({ preset });
  const sectionTopDividerMarginBottom = getSectionTopDividerMarginBottom({
    preset,
    centerSectionDividerMargin: styleParams.numbers.centerSectionDividerMargin,
    sectionTopDividerWidth: styleParams.numbers.sectionTopDividerWidth,
    expandCenterSectionDividerMargin: styleParams.numbers.expandCenterSectionDividerMargin,
  });
  const sectionBottomDividerMarginTop = getSectionBottomDividerMarginTop({
    sectionBottomDividerWidth: styleParams.numbers.sectionBottomDividerWidth,
  });
  const itemsDisplay = getItemsDisplay({ preset });
  const itemsGridTemplateColumns = getItemsGridTemplateColumns({
    preset,
    isMobile,
    gridNumOfColumns: styleParams.numbers.gridNumOfColumns,
  });

  const itemsColumnGap = getItemsColumnGap({ preset, gridItemsColumnGap: styleParams.numbers.gridItemsColumnGap });
  const itemsRowGap = getItemsRowGap({
    preset,
    gridItemsRowGap: styleParams.numbers.gridItemsRowGap,
    centerItemsRowGap: styleParams.numbers.centerItemsRowGap,
    expandCenterItemsRowGap: styleParams.numbers.expandCenterItemsRowGap,
  });

  const itemRootMargin = getItemRootMargin({
    preset,
    columnsItemsGap: styleParams.numbers.columnsItemsGap,
    sideBySideItemsGap: styleParams.numbers.sideBySideItemsGap,
    expandStripItemsGap: styleParams.numbers.expandStripItemsGap,
  });
  const firstItemDividerMarginBottom = getFirstItemDividerMarginBottom({
    itemDividerWidth: styleParams.numbers.itemDividerWidth,
    showItemDividers: styleParams.booleans.showItemDividers,
    itemRootMargin,
  });
  const itemGridTemplate = getItemGridTemplate({
    preset,
    isRTL,
    gridItemDetailsPadding: styleParams.numbers.gridItemDetailsPadding,
    columnsImageWidth,
    expandStripImageWidth,
    centerItemDetailsPadding: styleParams.numbers.centerItemDetailsPadding,
    columnsImagePosition: styleParams.numbers.columnsImagePosition,
    expandStripImagePosition: styleParams.numbers.expandStripImagePosition,
    showItemImage: styleParams.booleans.showItemImage,
    showDots: styleParams.booleans.showDots,
    isMobile,
  });
  const itemGridTemplateNoImage = getItemGridTemplateNoImage({
    preset,
    isRTL,
    gridItemDetailsPadding: styleParams.numbers.gridItemDetailsPadding,
    columnsImageWidth,
    expandStripImageWidth,
    centerItemDetailsPadding: styleParams.numbers.centerItemDetailsPadding,
    columnsImagePosition: styleParams.numbers.columnsImagePosition,
    expandStripImagePosition: styleParams.numbers.expandStripImagePosition,
    showDots: styleParams.booleans.showDots,
    isMobile,
  });
  const itemPadding = getItemPadding({
    preset,
    gridItemPadding: styleParams.numbers.gridItemPadding,
    columnsItemPadding: styleParams.numbers.columnsItemPadding,
    sideBySideItemPadding: styleParams.numbers.sideBySideItemPadding,
    centerItemPadding: styleParams.numbers.centerItemPadding,
    expandStripItemPadding: styleParams.numbers.expandStripItemPadding,
    expandCenterItemPadding: styleParams.numbers.expandCenterItemPadding,
  });
  const itemPriceJustifySelf = getItemPriceJustifySelf({ preset });
  const itemPriceWidth = getItemPriceWidth({ preset });
  const itemPriceMarginBottom = getItemPriceMarginBottom({ preset });
  const itemImageAspectRatio = getItemImageAspectRatio({
    preset,
    gridItemImageRatio: styleParams.numbers.gridItemImageRatio,
    columnsItemImageRatio: styleParams.numbers.columnsItemImageRatio,
    expandStripItemImageRatio: styleParams.numbers.expandStripItemImageRatio,
  });
  const itemImageMarginBottom = getItemImageMarginBottom({
    preset,
    gridItemImageBottomPadding: styleParams.numbers.gridItemImageBottomPadding,
  });

  const priceVariantsDisplay = getPriceVariantsDisplay({ preset });
  const priceVariantsJustifyContent = getPriceVariantsJustifyContent({ preset });
  const priceVariantsAlignItems = getPriceVariantsAlignItems({ preset });
  const priceVariantsFlexWrap = getPriceVariantsFlexWrap({ preset });
  const priceVariantsGap = getPriceVariantsGap({ preset });
  const priceVariantsMarginBottom = getPriceVariantsMarginBottom({ preset });

  const variantDisplay = getVariantDisplay({ preset });
  const variantGridTemplate = getVariantGridTemplate({ preset });
  const variantAlignItems = getVariantAlignItems({ preset });
  const variantWidth = getVariantWidth({ preset, showDots: styleParams.booleans.showDots });
  const variantDetailsJustifyContent = getVariantDetailsJustifyContent({ preset });
  const variantDetailsGridArea = getVariantDetailsGridArea({ preset });

  const showItemDescription = getElementDisplay({ shouldShow: styleParams.booleans.showItemDescription });

  const showItemPrice = getElementDisplay({ shouldShow: styleParams.booleans.showItemPrice, displayOption: 'flex' });

  const showItemCurrency = getElementDisplay({ shouldShow: styleParams.booleans.showItemCurrency });

  const showItemLabels = getElementDisplay({
    shouldShow:
      styleParams.booleans.showItemLabels &&
      (styleParams.booleans.showItemLabelsIcon || styleParams.booleans.showItemLabelsName),
    displayOption: 'flex',
  });

  const showItemLabelsIcon = getElementDisplay({ shouldShow: styleParams.booleans.showItemLabelsIcon });

  const showItemLabelsName = getElementDisplay({ shouldShow: styleParams.booleans.showItemLabelsName });

  const showItemImage = getElementDisplay({ shouldShow: styleParams.booleans.showItemImage && hasItemImage(preset) });

  const showItemDividers = getElementDisplay({
    shouldShow: styleParams.booleans.showItemDividers && hasItemDivider(preset),
  });

  const showItemDividerContainer = getElementDisplay({
    shouldShow: hasItemDivider(preset),
    displayOption: 'flex',
  });

  const showItemVariantsPrice = getElementDisplay({ shouldShow: styleParams.booleans.showItemVariantsPrice });

  const showItemVariantsName = getElementDisplay({ shouldShow: styleParams.booleans.showItemVariantsName });

  const showTabs = getElementDisplay({ shouldShow: styleParams.booleans.showTabs });

  const showMenuName = getElementDisplay({ shouldShow: styleParams.booleans.showMenuName });

  const showMenuDescription = getElementDisplay({ shouldShow: styleParams.booleans.showMenuDescription });

  const showSectionName = getElementDisplay({ shouldShow: styleParams.booleans.showSectionName });

  const showSectionDescription = getElementDisplay({ shouldShow: styleParams.booleans.showSectionDescription });

  const showSectionTopDivider = getElementDisplay({
    shouldShow: styleParams.booleans.showSectionDividers && hasSectionTopDivider(preset),
  });

  const showSectionBottomDivider = getElementDisplay({
    shouldShow: styleParams.booleans.showSectionDividers && hasSectionBottomDivider(preset),
  });

  const showDotsBorder = getElementDisplay({
    shouldShow: styleParams.booleans.showDots && styleParams.booleans.showItemPrice,
  });

  const showVariantsDotsBorder = getElementDisplay({
    shouldShow:
      styleParams.booleans.showDots &&
      styleParams.booleans.showItemVariantsName &&
      styleParams.booleans.showItemVariantsPrice,
  });

  const headerFlexDirection = getHeaderFlexDirection({
    gridHeaderFlexDirection: styleParams.numbers.gridHeaderFlexDirection,
    columnsHeaderFlexDirection: styleParams.numbers.columnsHeaderFlexDirection,
    sideBySideHeaderFlexDirection: styleParams.numbers.sideBySideHeaderFlexDirection,
    centerHeaderFlexDirection: styleParams.numbers.centerHeaderFlexDirection,
    expandStripHeaderFlexDirection: styleParams.numbers.expandStripHeaderFlexDirection,
    expandCenterHeaderFlexDirection: styleParams.numbers.expandCenterHeaderFlexDirection,
    preset,
    isMobile,
  });

  const headerContainerGridArea = getHeaderContainerGridArea({ preset });

  const dotSvgUrl = getDotSvgUrl({ size: styleParams.numbers.dotsSize });
  const nameAndPriceAlignSelf = getNameAndPriceAlignSelf({ showDots: styleParams.booleans.showDots });

  const itemDotsMargin = getDotsMargin({ nameFont: styleParams.fonts.itemTitleTheme });
  const variantDotsMargin = getDotsMargin({ nameFont: styleParams.fonts.variantTitleTheme });

  const headerJustifyContent = getHeaderJustifyContent({
    gridFlexDirection: styleParams.numbers.gridHeaderFlexDirection,
    columnsFlexDirection: styleParams.numbers.columnsHeaderFlexDirection,
    sideBySideFlexDirection: styleParams.numbers.sideBySideHeaderFlexDirection,
    centerFlexDirection: styleParams.numbers.centerHeaderFlexDirection,
    expandStripFlexDirection: styleParams.numbers.expandStripHeaderFlexDirection,
    expandCenterFlexDirection: styleParams.numbers.expandCenterHeaderFlexDirection,
    preset,
    isMobile,
  });

  const customCss = {
    columnsImageWidth,
    expandStripImageWidth,
    menuTextAlignment,
    sectionTextAlignment,
    itemTextAlignment,
    priceJustifyContent,
    navigationTextAlignment,
    showVariantsDivider,
    showMenusSideImage,
    widgetPadding,
    widgetOverflow,
    widgetDisplay,
    widgetGridTemplate,
    headerContainerPadding,
    menuContentGridArea,
    menuContentPadding,
    imageContainerGridArea,
    imageContainerPosition,
    imageContainerWidth,
    imageContainerHeight,
    imageContainerPaddingBottom,
    imageContainerTop,
    navigationMargin,
    menuMaxWidth,
    menuMargin,
    sectionsDisplay,
    sectionsGridTemplateColumns,
    sectionsRowGap,
    sectionsMarginTop,
    sectionsColumns,
    sectionsColumnsGap,
    sectionPadding,
    sectionMarginBottom,
    firstSectionMarginTop,
    sectionTopDividerMarginBottom,
    sectionBottomDividerMarginTop,
    itemsDisplay,
    itemsGridTemplateColumns,
    itemsColumnGap,
    itemsRowGap,
    itemRootMargin,
    firstItemDividerMarginBottom,
    itemGridTemplate,
    itemGridTemplateNoImage,
    itemPadding,
    itemPriceJustifySelf,
    itemPriceWidth,
    itemPriceMarginBottom,
    itemImageAspectRatio,
    itemImageMarginBottom,
    priceVariantsDisplay,
    priceVariantsJustifyContent,
    priceVariantsAlignItems,
    priceVariantsFlexWrap,
    priceVariantsGap,
    priceVariantsMarginBottom,
    variantDisplay,
    variantGridTemplate,
    variantAlignItems,
    variantWidth,
    variantDetailsJustifyContent,
    variantDetailsGridArea,
    showItemDescription,
    showItemPrice,
    showItemCurrency,
    showItemLabels,
    showItemImage,
    showItemDividers,
    showItemDividerContainer,
    showItemLabelsIcon,
    showItemLabelsName,
    showItemVariantsPrice,
    showItemVariantsName,
    showTabs,
    showMenuName,
    showMenuDescription,
    showSectionName,
    showSectionDescription,
    showSectionTopDivider,
    showSectionBottomDivider,
    showDotsBorder,
    showVariantsDotsBorder,
    dotSvgUrl,
    itemDotsMargin,
    variantDotsMargin,
    nameAndPriceAlignSelf,
    headerFlexDirection,
    headerContainerGridArea,
    headerJustifyContent,
  };

  return Object.fromEntries(Object.entries(customCss).filter(([_, value]) => value !== ''));
};
