import { SettingsParamType, createSettingsParams } from '@wix/tpa-settings';
import { MenusDisplayOptions } from './consts';
import type { LocationId } from 'root/types/locations';

export type IMenusSettingsParams = {
  menusDisplayOption: SettingsParamType.String;
  menusDisplayOrder?: SettingsParamType.Object;
  locationDisplayOrder?: SettingsParamType.Object;
};

export type MenuOrder = { id: string; checked: boolean };
export type LocationOrder = LocationId[];

export const MenusSettingsParams = createSettingsParams<IMenusSettingsParams>({
  menusDisplayOrder: {
    type: SettingsParamType.Object,
    getDefaultValue: () => undefined,
  },
  menusDisplayOption: {
    type: SettingsParamType.String,
    getDefaultValue: () => MenusDisplayOptions.All,
  },
  locationDisplayOrder: {
    type: SettingsParamType.Object,
    getDefaultValue: () => undefined,
  },
});
