import type { ControllerFlowAPI, ILocation } from '@wix/yoshi-flow-editor';
import type { PopulatedMenu } from 'root/apiTypes';
import type { NavigateToParams } from 'root/clientTypes';
import { HASH_TO_PREVENT_SCROLL } from 'root/consts';

const createCleanLink = (url: string) => {
  const link = new URL(url);
  link.searchParams.delete('menu');
  link.searchParams.delete('menuId');
  link.hash = HASH_TO_PREVENT_SCROLL;

  return link;
};

export const buildMenuUrlFromCurrentUrl = ({ currentUrl, isFirst, urlQueryParam, menuId }: NavigateToParams) => {
  const link = createCleanLink(currentUrl);
  if (isFirst) {
    return link.toString();
  }

  if (urlQueryParam) {
    link.searchParams.set('menu', urlQueryParam);
  } else if (menuId) {
    link.searchParams.set('menuId', menuId);
  } else {
    throw new Error('urlQueryParam or menuId must be provided');
  }

  return link.toString();
};

export const goToPage = (flowAPI: ControllerFlowAPI) => {
  return ({ isFirst, urlQueryParam, menuId }: NavigateToParams) => {
    const { location } = flowAPI?.controllerConfig?.wixCodeApi;
    if (!location) {
      return;
    }

    if (isFirst) {
      location.queryParams.remove(['menu']);
      return;
    }
    if (urlQueryParam) {
      location.queryParams.add({ menu: urlQueryParam });
    } else if (menuId) {
      location.queryParams.add({ menuId });
    } else {
      throw new Error('menu.urlQueryParam or menu.id must be provided');
    }
  };
};

export const getMenuToDisplayFromUrl = ({
  urlQuery = {},
  displayableMenus,
  isMultiLocationExperimentEnabled = false,
}: {
  urlQuery: ILocation['query'];
  displayableMenus: PopulatedMenu[];
  isMultiLocationExperimentEnabled?: boolean;
}) => {
  const menuQueryParam = urlQuery.menu;
  const menuId = urlQuery.menuId;

  if (!isMultiLocationExperimentEnabled) {
    if (!menuQueryParam && !menuId) {
      return { activeTab: 0, initialLocation: undefined };
    }
    const menuToDisplayIndex = displayableMenus.findIndex((menu: PopulatedMenu) =>
      menuQueryParam
        ? menu.urlQueryParam === menuQueryParam || menu.urlQueryParam === encodeURIComponent(menuQueryParam)
        : menu.id === menuId
    );

    return { activeTab: menuToDisplayIndex, initialLocation: undefined };
  }
  if (!menuQueryParam && !menuId) {
    return { activeTab: 0, initialLocation: displayableMenus[0].businessLocationId };
  }

  const menuToDisplay = displayableMenus.find((menu: PopulatedMenu) =>
    menuQueryParam
      ? menu.urlQueryParam === menuQueryParam || menu.urlQueryParam === encodeURIComponent(menuQueryParam)
      : menu.id === menuId
  );

  if (!menuToDisplay) {
    return { activeTab: -1, initialLocation: undefined };
  }

  const initialLocation = menuToDisplay.businessLocationId;
  const activeTab = displayableMenus
    .filter((menu) => menu.businessLocationId === initialLocation)
    .findIndex((menu) => menu.id === menuToDisplay.id);

  return { activeTab, initialLocation };
};
