import { useGetDisplayStylesParams } from 'root/hooks/useGetDisplayStylesParams';
import type { MenuCheckbox } from 'root/clientTypes';
import { isSectionChecked, isPriceShowed } from 'root/utils/display';
import { useGetDisplaySettingsParams } from 'root/hooks/useGetDisplaySettingsParams';
import { useItemsContext } from 'root/contexts/ItemsContext';
import type { ItemVariant } from 'root/apiTypes';
import { useGetHtmlTag } from 'root/hooks/useGetHtmlTag';

export const useVariantLogic = ({ variant }: { variant: ItemVariant }) => {
  const { sectionId } = useItemsContext();

  const { showItemVariantsCurrency } = useGetDisplayStylesParams();
  const { showZeroPrice, checkPricingMenus, showZeroPriceToAll } = useGetDisplaySettingsParams();
  const variantTitleHtmlTag = useGetHtmlTag('variantTitleTheme');
  const variantPriceHtmlTag = useGetHtmlTag('variantPriceTheme');

  const formattedPrice = showItemVariantsCurrency
    ? variant.priceInfo.formattedPrice
    : variant.priceInfo.formattedPriceNoCurrency ?? '';

  const priceOptionSectionChecked =
    showZeroPriceToAll || isSectionChecked(checkPricingMenus as MenuCheckbox[], sectionId ?? '');

  const shouldShowVariantsPrice = isPriceShowed({
    price: variant.priceInfo.price ?? '',
    showZeroPrice: showZeroPrice as boolean,
    sectionChecked: priceOptionSectionChecked as boolean,
  });

  return {
    formattedPrice,
    shouldShowVariantsPrice,
    variantTitleHtmlTag,
    variantPriceHtmlTag,
  };
};
