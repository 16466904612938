import React from 'react';
import type { FC } from 'react';
import type { ILocation, WidgetProps } from '@wix/yoshi-flow-editor';
import { Menu } from 'root/components/Menus/Menu/Menu';
import type { PopulatedMenu } from 'root/apiTypes';
import { dataHooks } from 'root/dataHooks';
import type { NavigateToFn } from 'root/clientTypes';
import { useMenusLogic } from './Widget.logic';
import { AppWrapper } from 'root/components/Menus/AppWrapper/AppWrapper';
import { classes, st } from './Widget.st.css';
import { Navigation } from 'root/components/Menus/Navigation/Navigation';
import { MenuState } from 'root/components/Menus/MenuState/MenuState';
import { WowImage, ImageResizeOptions } from 'wix-ui-tpa/cssVars';
import { DEFAULT_MENUS_SIDE_IMAGE } from 'root/assets/images/MenusSideImage';
import { Locations } from 'root/components/Menus/Locations/Locations';
import type { LocationsList } from 'root/types/locations';

export type ControllerProps = {
  menus: PopulatedMenu[];
  locations?: LocationsList;
  navigateTo: NavigateToFn;
  messageError?: string;
  url: ILocation['url'];
  fitToContentHeight: true;
  initialActiveTab: number;
  initialLocation?: string;
};

export const Menus: FC<WidgetProps<ControllerProps>> = ({
  menus,
  locations,
  navigateTo,
  messageError,
  url,
  initialActiveTab,
  initialLocation,
}) => {
  const {
    t,
    activeTab,
    displayableMenus,
    shouldShowTabs,
    setActiveTab,
    menusSideImage,
    displayableLocations,
    showLocations,
    currentLocation,
    setCurrentLocation,
    isRow,
  } = useMenusLogic({
    menus,
    locations,
    initialActiveTab,
    initialLocation,
  });

  if (messageError) {
    return <MenuState content={messageError} />;
  }

  return displayableMenus?.length > 0 ? (
    <div>
      <div className={st(classes.root)} data-hook={dataHooks.menus.container}>
        <div className={classes.imageContainer}>
          <WowImage
            shouldUseLQIP
            className={classes.sideBySideImage}
            data-hook={dataHooks.menu.sideImage}
            resize={ImageResizeOptions.cover}
            sourceHeight={menusSideImage?.height}
            sourceWidth={menusSideImage?.width}
            src={menusSideImage.url ?? DEFAULT_MENUS_SIDE_IMAGE}
          />
        </div>
        <div className={`${classes.headerContainer} ${isRow && classes.headerContainerRow}`}>
          {showLocations && (
            <Locations
              currentLocation={currentLocation ?? ''}
              locations={displayableLocations}
              setActiveTab={setActiveTab}
              setCurrentLocation={setCurrentLocation}
            />
          )}
          {shouldShowTabs && (
            <Navigation
              activeItem={activeTab}
              currentUrl={url}
              isRow={isRow}
              menus={displayableMenus}
              navigateTo={navigateTo}
              setActiveItem={setActiveTab}
            />
          )}
        </div>
        <div className={classes.menuContent}>
          <Menu menu={displayableMenus[activeTab]} />
        </div>
      </div>
    </div>
  ) : (
    <MenuState content={t('component.menu.empty-state')} />
  );
};

export const Widget: FC<WidgetProps<ControllerProps>> = (props) => {
  return (
    <AppWrapper>
      <Menus {...props} />
    </AppWrapper>
  );
};
